<template>
  <div>
    <div v-if="selectedAccountId && selectedAccountId !== 'all'">
      <el-container class="h-full">
        <el-container>
          <el-main>
            <div style="height: 50px;">
              <page-header
                style="max-width: 90%; padding-left: 0.7rem"
                :title="__('Account Profile')"
              />
            </div>
            <el-tabs
              v-model="activeTab"
              style="margin-top: 10px; padding:1.5rem 1.5rem 2rem 0.7rem;"
            >
              <el-tab-pane
                :label="__('Profile Settings')"
                name="profileSettings"
              >
                <my-ac-profile> </my-ac-profile>
              </el-tab-pane>
              <el-tab-pane :label="__('Alert Settings')" name="alertSettings">
                <ac-alert-settings> </ac-alert-settings>
              </el-tab-pane>
            </el-tabs>
          </el-main>
        </el-container>
        <el-aside class="form-aside relative" :width="`${asideWidth}px`">
          <div class="aside-content">
            <div style="font-size: 14px;padding: 30px 15px;color: #A0A8B5">
              {{ asideText }}
            </div>
          </div>
          <slot name="form"></slot>
        </el-aside>
      </el-container>
    </div>
    <div v-else>
      <not-scoped scope="Account" resource="Account profile" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
// import _ from "lodash";
import NotScoped from "@/components/NotScoped";
import MyAcProfile from "./my-ac-profile/index";
import AcAlertSettings from "./ac-alert-settings/index";

// import routerUtils from "@/utils/router";

export default {
  name: "AcProfileIndex",
  components: {
    PageHeader,
    NotScoped,
    MyAcProfile,
    AcAlertSettings
  },
  props: {
    formAction: {
      default: ""
    },
    asideText: {
      default: ""
    },
    asideButtonText: {
      default: "Create"
    },
    asideWidth: {
      type: Number,
      required: false,
      default: 274
    }
  },
  data() {
    return {
      activeTab: "profileSettings", //
      activeName: "first",
      isLoading: false
    };
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  methods: {
    submitForm() {}
  },
  mounted() {
    this.scope = "ac";
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/button.scss";
.h-full {
  height: 100%;
}

.aside-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.form-aside {
  background: white;
  border-left: #f5f5f8 1px solid;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.someBtn {
  height: 44px;
  background-color: var(--theme-color);
  border-color: transparent;
  &:hover {
    background-color: var(--theme-hover-color);
    border-color: transparent;
  }
}

.tabs {
  font-size: 16px;
  cursor: pointer;
}
.tabs ::v-deep .el-tabs__item.is-active {
  color: var(--theme-color);
}

.tabs ::v-deep .el-tabs__item:hover {
  color: var(--theme-hover-color);
}

.tabs ::v-deep .el-tabs__active-bar {
  background-color: var(--theme-color);
}

.list-table {
  ::v-deep .el-table {
    padding: 10px;
    transition: width 1s ease-in-out;
    min-width: 194px;
  }
  ::v-deep .el-table__header th.is-leaf,
  .el-table td {
    border-bottom: 2px solid #f5f5f8;
  }
  ::v-deep .el-table__header-wrapper th > .cell {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #a0a8b5;
  }
  ::v-deep .el-table__body tr > td:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  ::v-deep .el-table__body tr > td:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ::v-deep .el-table__body tr:first-child:hover {
    outline: 2px solid var(--theme-outline-color);
  }
  ::v-deep .el-table__body tr:hover {
    outline: 2px solid var(--theme-outline-color);
  }

  ::v-deep .el-table__body tr:hover > td {
    background-color: var(--theme-row-hover-color);
  }

  ::v-deep .el-table__body tr.current-row > td {
    background-color: var(--theme-row-hover-color);
  }
}
.card {
  width: 120px;
  height: 120px;
  ::v-deep .el-card__body {
    margin-top: 25px;
    margin-left: 20px;
  }
  &:hover {
    background-color: var(--theme-row-hover-color);
    border-color: transparent;
  }
}
</style>
