<template>
  <el-form
    id="myAcProfileForm"
    ref="myAcProfileForm"
    :rules="rules"
    :model="acProfileSettings"
    label-position="top"
    size="large"
    hide-required-asterisk
    v-loading="isLoading || loading"
  >
    <el-row type="flex">
      <el-col :span="12">
        <el-form-item :label="__('Account ID')">
          <div>{{ acProfileSettings.ac_id }}</div>
        </el-form-item>
        <el-form-item :label="__('Name')" prop="ac_name">
          <el-input v-model="acProfileSettings.ac_name"></el-input>
        </el-form-item>

        <el-form-item :label="__('Note')" prop="note">
          <el-input v-model="acProfileSettings.note"></el-input>
        </el-form-item>

        <el-form-item :label="__('Logo')" prop="logopath">
          <logo-uploader
            :folder="folder"
            :file-url="acProfileSettings.logo_url"
            @on-success="handleSuccess"
            @on-error="handleError"
          />
        </el-form-item>

        <el-form-item label="" prop="display_date_format">
          <display-date-format-dropdown
            v-model="acProfileSettings.display_date_format"
            @display-date-format-changed="displayDateFormatChanged"
          />
        </el-form-item>

        <el-form-item :label="__('Timezone')" prop="default_timezone">
          <timezone-dropdown
            :default_timezone="acProfileSettings.default_timezone"
            @timezone-changed="handleTimezoneChange"
            :key="timezoneKey"
          />
        </el-form-item>

        <el-form-item
          v-if="isSystemUser"
          :label="__('Datastore Migration')"
          prop="allow_datastore_migration"
        >
          <el-switch
            v-model="acProfileSettings.allow_datastore_migration"
            @change="handleAllowDatastoreMigrationToggle"
            :active-value="true"
            :inactive-value="false"
          ></el-switch>
        </el-form-item>

        <el-form-item>
          <el-button
            :disabled="!this.validatePermission()"
            type="primary"
            @click="submitForm"
            class="submitBtn"
          >
            {{ __("Update") }}
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import TimezoneDropdown from "@/components/TimezoneDropdown.vue";
import LogoUploader from "@/components/uploaders/types/AvatarUploader";
import DisplayDateFormatDropdown from "@/components/DisplayDateFormatDropdown";
import { MessageBox } from "element-ui";

export default {
  name: "MyAcProfileIndex",
  components: {
    LogoUploader,
    TimezoneDropdown,
    DisplayDateFormatDropdown
  },
  data() {
    return {
      folder: "logo",
      timezoneKey: 0,
      selectedAcIds: 0,
      acProfileSettings: {
        ac_name: "",
        note: "",
        logo_url: "",
        // ob_thread: 0,
        // ob_max: 1,
        // message_balance: 0,
        default_timezone: "",
        allow_datastore_migration: false
        // password_policy_id: ""
      },
      rules: {
        ac_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Account Name is required")
          }
        ],
        default_timezone: [
          {
            required: true,
            trigger: "blur",
            message: __("Timezone is required")
          }
        ]
      },
      isLoading: false
    };
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("accounts", {
      loading: state => state.isLoading,
      accountData: state => state.accountData
    }),
    isSystemUser() {
      return this.$auth.user().roles.includes("system");
    }
  },

  methods: {
    ...mapActions("accounts", {
      getAccountData: "getAccountData",
      updateAccount: "updateAccount"
    }),

    // ...mapActions("passwordpolicies", {
    //   getPasswordPolicies: "getPasswordPolicies"
    // }),
    validatePermission() {
      return this.can("manage.ac-profile.write");
    },

    handleAllowDatastoreMigrationToggle(value) {
      MessageBox.confirm(
        "Are you sure you want to " +
          (value ? "enable" : "disable") +
          " datastore migration?",
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning"
        }
      )
        .then(() => {
          // User confirmed, toggle the switch
          this.acProfileSettings.allow_datastore_migration = value;
        })
        .catch(() => {
          // User canceled, reset the switch value
          this.acProfileSettings.allow_datastore_migration = !value;
        });
    },
    handleSuccess(res) {
      console.log(res.url);
      this.acProfileSettings.logo_url = res.url;
      this.acProfileSettings.logopath = res.path;
      this.uploadedFilePath = res.path;
    },

    handleError(err) {
      console.log(err);
    },

    handleTimezoneChange(timezone) {
      this.$set(this.acProfileSettings, "default_timezone", timezone);
    },

    submitForm() {
      this.isLoading = true;
      this.$refs.myAcProfileForm.validate(valid => {
        if (valid) {
          this.updateAccount(this.acProfileSettings)
            .then(() => {
              this.isLoading = false;
              this.$message({
                type: "success",
                message: __("Account Profile updated successfully.")
              });
            })
            .catch(err => {
              this.isLoading = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
      this.isLoading = false;
    },

    /**
     * reset ac profile setting fields
     */
    resetForm() {
      this.$set(this.acProfileSettings, "ac_name", "");
      this.$set(this.acProfileSettings, "note", "");
      this.$set(this.acProfileSettings, "logo_url", "");
      this.$set(this.acProfileSettings, "default_timezone", "");
      this.$set(this.acProfileSettings, "display_date_format", "");
      this.$set(this.acProfileSettings, "allow_datastore_migration", false);

      this.timezoneKey++;
    },

    async reloadForm() {
      this.isLoading = true;
      this.selectedAcIds = this.selectedAccountId;
      await this.getAccountData(this.selectedAccountId);
      if (!_.isEmpty(this.accountData)) {
        this.acProfileSettings = _.cloneDeep(this.accountData);
        this.timezoneKey++;
      } else {
        this.resetForm();
      }
      this.isLoading = false;
    },
    /**
     * handle display date format changed
     * @param displayDateFormat
     */
    displayDateFormatChanged(displayDateFormat) {
      this.acProfileSettings.display_date_format = displayDateFormat;
    }
  },

  watch: {
    selectedAccountId: {
      immediate: true,
      handler: "reloadForm"
    }
  },

  async mounted() {
    this.isLoading = true;
    // await this.getPasswordPolicies();
    await this.getAccountData(this.selectedAccountId);
    if (!_.isEmpty(this.accountData)) {
      this.acProfileSettings = _.cloneDeep(this.accountData);
      this.timezoneKey++;
    } else {
      this.resetForm();
    }
    this.isLoading = false;
  },

  beforeDestroy: function() {
    this.$echo.leave("ac_settings." + this.$auth.user().user_id);
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/button.scss";

.el-tag.el-tag--danger {
  background-color: #feedee !important;
  border-color: #fedadd !important;
  color: #f84856 !important;
}

.el-tag.el-tag--success {
  background-color: #eef8ed !important;
  border-color: #dcf1da !important;
  color: #51b848 !important;
}
</style>
